import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { config } from '@keytrade/functions';

import { getTransparentColor } from '@/utils/StyleUtils';
import Paragraph from '@/components/Paragraph';
import Image, { ImageType } from '@/components/Image';

const { list: colors } = config.colors;

export type AuthorType = {
  name?: string;
  role?: string;
  picture: ImageType;
};
const Picture = styled(Image)`
  border-radius: 50%;
  border: 1px solid ${getTransparentColor('Blue cloudy')};
`;
const ImageContent = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 4rem;
`;
const AuthorContent = styled.div`
  display: flex;
  align-items: center;
`;
const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 0 1.6rem;
  text-align: left;
`;

const SmallParagraph = styled.p`
  font-size: 1.4rem;
  line-height: 2rem;
  height: 2rem;
  color: ${colors.BlueMoon};
`;
type Props = AuthorType;

const Author: React.FC<Props> = ({ name, role, picture }) => {
  return (
    <AuthorContent>
      <ImageContent>
        <Picture {...picture} />
      </ImageContent>
      <InfoContent>
        <Paragraph weight='semiBold'>{name}</Paragraph>
        <SmallParagraph>{role}</SmallParagraph>
      </InfoContent>
    </AuthorContent>
  );
};

export default Author;
export const authorQuery = graphql`
  fragment AuthorFragment on ContentfulAuthor {
    name
    role
    picture {
      gatsbyImageData(width: 80, aspectRatio: 1)
      ...ImageFragment
    }
  }
`;

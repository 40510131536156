import React from 'react';
import { graphql } from 'gatsby';

import Page, { PageData, PageInfoType, PageType } from '@/components/Page';
import Article, { ArticleType } from '@/components/blog/Article';
import { ArticleBlockType } from '@/components/blog/ArticleBlock';
import { addPrefixToLanguageSlugs } from '@/utils/SlugUtils';

type ArticleTypeData = ArticleType;
type ArticlePageData = PageData & {
  pageType: ArticleTypeData;
};
type Props = PageType & {
  data: {
    contentfulPage: ArticlePageData;
    relatedArticles: {
      nodes: Array<ArticleBlockType>;
    };
    allBlogPages: {
      nodes: Array<{ page: Array<PageInfoType> }>;
    };
  };
  pageContext: {
    blogPath: string;
  };
};

const ArticlePage: React.FC<Props> = (props) => {
  const page = props.data.contentfulPage;

  const article: ArticleType = {
    ...page.pageType,
    local: page.node_locale,
    path: props.path,
    blogPath: props.pageContext.blogPath,
    relatedArticles: props.data.relatedArticles.nodes,
    defaultPaths: { blogPath: props.pageContext.blogPath },
  };

  article.relatedArticles.forEach(
    (relatedArticle) => (relatedArticle.blogPath = article.blogPath),
  );

  addPrefixToLanguageSlugs(
    props.data.allLanguages.nodes,
    props.data.allBlogPages.nodes
      .filter((node) => node.page)
      .map((node) => node.page[0]),
  );

  return (
    <Page {...props}>
      <Article {...article} />
    </Page>
  );
};

export default ArticlePage;

export const pageQuery = graphql`
  query ArticleQuery(
    $contentfulId: String!
    $locale: String
    $articles: [String]
  ) {
    contentfulPage(
      contentful_id: { eq: $contentfulId }
      node_locale: { eq: $locale }
    ) {
      node_locale
      ...PagePathFragment

      seoMetadata {
        ...SeoMetaDataFragment
      }
      pageType {
        ... on ContentfulArticle {
          ...ArticleFragment
        }
      }
    }
    allBlogPages: allContentfulBlogPageType {
      nodes {
        page {
          node_locale
          ...PagePathFragment
        }
      }
    }
    allLanguages: allContentfulPage(
      filter: { contentful_id: { eq: $contentfulId } }
    ) {
      nodes {
        node_locale
        ...PagePathFragment
      }
    }
    contentfulNavigation(node_locale: { eq: $locale }) {
      ...NavigationFragment
    }
    contentfulLoginDialog(node_locale: { eq: $locale }) {
      ...LoginFragment
    }
    contentfulFooter(node_locale: { eq: $locale }) {
      ...FooterFragment
    }
    relatedArticles: allContentfulArticle(
      filter: { contentful_id: { in: $articles }, node_locale: { eq: $locale } }
    ) {
      nodes {
        ...ArticleBlockFragment
      }
    }
  }
`;

import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import slugify from 'slugify';
import { Helmet } from 'react-helmet';
import { Icon } from '@keytrade/components-icon';
import { config } from '@keytrade/functions';

import { mediaQuery } from '@/utils/helpers';
import { ArticleBlockType } from '@/components/blog/ArticleBlock';
import Container from '@/components/Container';
import Richtext, { RichTextType } from '@/components/Richtext';
import Disclaimer, { DisclaimerType } from '@/components/Disclaimer';
import { getBackgroundColor, getTransparentColor } from '@/utils/StyleUtils';
import { LinkStyle } from '@/components/Link';
import InternalLink from '@/components/InternalLink';
import Title from '@/components/Title';
import ArticlesBlock from '@/components/blocks/ArticlesBlock';
import { ImageType } from '@/components/Image';
import useTranslations from '@/hooks/useTranslations';
import SingleCenteredBlock from '@/components/blocks/SingleCenteredBlock';

import Author, { AuthorType } from './Author';
import ArticleTag, { ArticleTagType } from './ArticleTag';
import getMetaSetter from '../seo/Meta.utils';

export type ArticleType = RichTextType & {
  title: string;
  tags: Array<ArticleTagType>;
  author?: AuthorType;
  sharingOnSocialMedia?: Array<string>;
  disclaimer?: DisclaimerType;
  date: string;
  timetoread: number;
  locale: string;
  path: string;
  blogPath: string;
  node_locale: string;
  relatedArticles: Array<ArticleBlockType>;
  image: ImageType;
  cta_button?: string;
  createdAt: string;
  updatedAt: string;
};

type Props = ArticleType;

const { list: colors } = config.colors;

const TagsContent = styled.div`
  margin-bottom: 5rem;
`;
const InfoContent = styled.div`
  display: flex;
  border-top: 1px solid ${getTransparentColor('Blue cloudy')};
  border-bottom: 1px solid ${getTransparentColor('Blue cloudy')};
  padding: 2.2rem 0;
  margin: 3.2rem 0;
`;
const DateContent = styled.div`
  display: flex;
  align-items: center;
  margin: 0 1.2rem 0 0;
`;

const DurationContent = styled.div`
  display: flex;
  align-items: center;
`;
const ShareContent = styled.div`
  margin: 3.2rem 0;
  padding: 2.6rem;
  background: ${getBackgroundColor('White')};
  border-radius: 16px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05),
    0px 2px 7px 0px rgba(0, 0, 0, 0.05);
`;
const FooterContent = styled.footer`
  display: flex;
  background-color: ${getBackgroundColor('Light blue')};
  padding: 0 0 7.2rem 0;
`;

const ArticleContainer = styled(Container)`
  padding-bottom: 11.2rem;
  padding-top: 11.4rem;
  ${mediaQuery.lg(`
  padding-top: 16.2rem;
  `)}
`;

const ArticlesWrapper = styled.div`
  ${mediaQuery.md(`  
    h2.heading {
        font-size: 32px !important;
      };
      h3.heading {
        font-size: 28px !important;
      };
  `)}
`;

const SmallParagraph = styled.p`
  font-size: 1.4rem;
  line-height: 2rem;
  height: 2rem;
  color: ${colors.BlueMoon};
`;

const Article: React.FC<Props> = ({
  title,
  author,
  tags,
  richText,
  defaultPaths,
  date,
  timetoread,
  disclaimer,
  blogPath,
  node_locale,
  relatedArticles,
  image,
  cta_button,
  createdAt,
  updatedAt,
}) => {
  const { t, FormattedMessage } = useTranslations();
  const meta = [];
  const addMeta = getMetaSetter(meta);

  if (image?.file.url) {
    const { url } = image.file;
    const { width, height } = image.file.details.image;

    addMeta('og:image', url);
    addMeta('og:image:width', width.toString());
    addMeta('og:image:height', height.toString());
    addMeta('twitter:image', url);
  }
  const dateCustomFomart =
    node_locale === 'fr' || node_locale === 'nl'
      ? 'dd MMMM yyyy'
      : 'MMMM dd, yyyy';

  return (
    <ArticlesWrapper>
      <Helmet meta={meta}>
        <script type='application/ld+json'>
          {`{
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "headline": "${title}",
            "image": ["https:${image?.file?.url}"],
            "datePublished": "${createdAt}",
            "dateModified": "${updatedAt}",
            "author": {
              "@type": "Person",
              "name": "${author?.name}"
            }
          }`}
        </script>
      </Helmet>
      <ArticleContainer narrow={true}>
        <InternalLink linkStyle={LinkStyle.BACK} to={blogPath}>
          {t('back_to_blog')}
        </InternalLink>
        <Title size='xxl' level='h1' margin='3.2rem 0 1.8rem'>
          {title}
        </Title>
        {tags && (
          <TagsContent>
            {React.Children.toArray(
              tags.map((tag) => {
                return ArticleTag({
                  ...tag,
                  url: `${blogPath}/${slugify(tag.text, {
                    lower: true,
                    strict: true,
                  })}`,
                });
              }),
            )}
          </TagsContent>
        )}
        <Author {...author} />
        <InfoContent>
          <DateContent>
            <Icon icon='icn_calendar' color={colors.BlueMoon} />
            <SmallParagraph>
              {DateTime.fromISO(date)
                .setLocale(node_locale)
                .toFormat(dateCustomFomart)}
            </SmallParagraph>
          </DateContent>
          <DurationContent>
            <Icon icon='icn_clockLight' color={colors.BlueMoon} />
            <SmallParagraph>
              <FormattedMessage
                id='time_to_read'
                values={{ amount: timetoread }}
              />
            </SmallParagraph>
          </DurationContent>
        </InfoContent>
        <Richtext
          data={richText}
          defaultPaths={defaultPaths}
          options={{ noCheckMarkListIcons: true }}
          locale={node_locale}
        />
      </ArticleContainer>
      {cta_button && <SingleCenteredBlock {...cta_button} />}
      <FooterContent>
        <Container narrow={true}>
          <ShareContent>
            <InternalLink linkStyle={LinkStyle.BACK} to={blogPath}>
              {t('back_to_blog')}
            </InternalLink>
          </ShareContent>
          {disclaimer && <Disclaimer {...disclaimer} />}
        </Container>
      </FooterContent>
      {relatedArticles && (
        <ArticlesBlock
          articles={relatedArticles}
          title={t('related_articles')}
          backgroundColor={'Blue Fog'}
        />
      )}
    </ArticlesWrapper>
  );
};

export default Article;

export const articleQuery = graphql`
  fragment ArticleFragment on ContentfulArticle {
    title
    sharingOnSocialMedia
    timetoread
    date
    node_locale
    author {
      ...AuthorFragment
    }
    richText {
      ...RichTextFragment
    }
    tags {
      ...ArticleTagFragment
    }
    disclaimer {
      ...DisclaimerFragment
    }
    image {
      file {
        url
        details {
          image {
            width
            height
          }
        }
      }
    }
    cta_button {
      ... on ContentfulSingleCenteredBlock {
        ...SingleCenteredBlockFragment
      }
    }
    createdAt
    updatedAt
  }
`;

import React from 'react';
import SEO from './SEO';
import { graphql, PageProps } from 'gatsby';
import { Path } from '@/components/Page';

export type SEOMetaProps = {
  seoMetadata: SEOMetaType;
  path: Path;
  alternates: Array<Path>;
};

export type SEOMetaType = {
  seoTitle: string;

  seoDescription: {
    seoDescription: string;
  };
  socialDescription: {
    socialDescription: string;
  };
  socialTitle: string;
  socialImage: {
    fixed: {
      src: string;
    };
  };
  twitterImage: {
    fixed: {
      src: string;
    };
  };
  selligentMainTag: string;
  commandersActPageName: string;
  commandersActProductName: string;
};

export type Props = PageProps & {
  data: {
    site: {
      siteMetadata: {
        siteName: string;
      };
    };
    seoMetadata: SEOMetaType;
  };
};

const SEOMeta: React.FC<SEOMetaProps> = (props) => {
  const { seoMetadata, path, alternates } = props;

  if (seoMetadata === null) {
    return null;
  }

  const seoMetaDescription = seoMetadata?.seoDescription?.seoDescription;
  const socialTitle = seoMetadata.socialTitle ?? seoMetadata.seoTitle;
  const socialDescription = seoMetadata.socialDescription?.socialDescription;
  const socialImage = seoMetadata.socialImage?.fixed?.src;
  const twitterImage = seoMetadata.twitterImage?.fixed?.src;
  const selligentMainTag = seoMetadata.selligentMainTag;
  const commandersActPageName = seoMetadata.commandersActPageName;
  const commandersActProductName = seoMetadata.commandersActProductName;

  return (
    <SEO
      path={path}
      alternates={alternates}
      title={seoMetadata.seoTitle}
      description={seoMetaDescription}
      socialTitle={socialTitle}
      socialDescription={socialDescription}
      twitterImage={twitterImage}
      socialImage={socialImage}
      selligentMainTag={selligentMainTag}
      commandersActPageName={commandersActPageName}
      commandersActProductName={commandersActProductName}
    />
  );
};

export default SEOMeta;

export const seoMetaDataFragmentQuery = graphql`
  fragment SeoMetaDataFragment on ContentfulSeoMetadata {
    seoTitle
    seoDescription {
      seoDescription
    }
    socialDescription {
      socialDescription
    }
    socialTitle
    socialImage {
      fixed(width: 1200, height: 630) {
        ...GatsbyContentfulFixed
      }
    }
    twitterImage: socialImage {
      fixed(width: 260, height: 260) {
        ...GatsbyContentfulFixed
      }
    }
    selligentMainTag
    commandersActPageName
    commandersActProductName
  }
`;

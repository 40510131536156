import React from 'react';
import { useLocalization } from '@plugins/keytrade-localization/useLocalization';
import useLocalStorage from '@/hooks/useLocalStorage';
import { LANGUAGE_STORAGE_KEY } from '@/utils/constants';
import { useEffect } from 'react';
import { LanguageSlug } from '@/components/navigation/LanguageSwitch';
import { generateSlug } from '@/utils/SlugUtils';

const LanguageRedirect = ({
  languageSlugs,
  children,
  location,
}: React.PropsWithChildren<{
  languageSlugs: Array<LanguageSlug>;
  location: URL;
}>): JSX.Element => {
  const { locale, navigate } = useLocalization();
  const [storedLanguage] = useLocalStorage(LANGUAGE_STORAGE_KEY, locale);

  useEffect(() => {
    if (storedLanguage !== locale) {
      const matchingLanguageSlug = languageSlugs.find(
        (slug) => slug.node_locale === storedLanguage,
      );

      const params = new URLSearchParams(location.search);
      const loginParam = params.get('login');

      if (loginParam) {
        navigate(
          `${generateSlug(
            matchingLanguageSlug,
            matchingLanguageSlug.slugPrefix,
          )}?login=${loginParam}`,
          storedLanguage,
          {
            replace: true,
          },
        );
      } else {
        navigate(
          generateSlug(matchingLanguageSlug, matchingLanguageSlug.slugPrefix),
          storedLanguage,
          {
            replace: true,
          },
        );
      }
    }
  }, [storedLanguage, locale]);

  return <>{children}</>;
};

export default LanguageRedirect;

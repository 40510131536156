import { Path } from '@/components/Page';

export const getSelligentScript = (
  isProductionEnv: boolean,
  selligentMainTag: string,
  path: Path,
): string => {
  const finalSelligentMainTag = selligentMainTag || 'NT';
  const envToUse = isProductionEnv ? 'prod' : 'test';
  const staticPath = isProductionEnv ? 'static' : 'static.test';
  const filePath = `https://${staticPath}.keytradebank.be/marketing/cms/public/scripts/selligent_${envToUse}.js`;

  return `
    var SELLIGENT_MAINTAG = "${finalSelligentMainTag}";
    var SELLIGENT_LANGUAGE = "${path.lang.toUpperCase()}";
    var wa = document.createElement("script"),
    wa_s = document.getElementsByTagName("script")[0];
    wa.src = "${filePath}";
    wa.type = "text/javascript";
    wa_s.parentNode.insertBefore(wa, wa_s);
    wa.bt_queue = [];

    if (typeof bt_trackingFinishedCallback === 'undefined') bt_trackingFinishedCallback = function() {};
    if (typeof bt_errorCallback === 'undefined') bt_errorCallback = function() {};
    if (typeof buildTarget === 'undefined') buildTarget = function() {};
  `;
};

export const getCommanderActScript = (
  commandersActPageName: string,
  commandersActProductName: string,
  path: Path,
): string => {
  const finalCommandersActPageName = commandersActPageName || 'NT';
  const finalCommandersActProductName = commandersActProductName || 'NT';

  return `
    var tc_vars = {
      "env_work": "prod",
      "env_language": "${path.lang.toUpperCase()}",
      "env_cookie_consent": "true",
      "page_name": "${finalCommandersActPageName}",
      "product_name": "${finalCommandersActProductName}"
    };
  `;
};

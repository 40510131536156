import React from 'react';
import { Helmet } from 'react-helmet';

import { SiteMetaDataType, useSiteMetadata } from '@/hooks/useSiteMetadata';
import { fixedProtocol } from '@/utils/URLUtils';
import { Path } from '@/components/Page';
import { isProductionEnv } from '@/utils/envHelper';

import getMetaSetter from './Meta.utils';
import {
  getCommanderActScript,
  getSelligentScript,
} from './ExternalScripts.utils';

type MetaProperty = {
  name: string;
  content: any;
  property?: undefined;
};

type SEOProps = {
  description: string;
  meta?: Array<MetaProperty>;
  title: string;
  socialTitle: string;
  socialDescription: string;
  socialImage: string;
  twitterImage: string;
  alternates: Array<Path>;
  path: Path;
  selligentMainTag: string;
  commandersActPageName: string;
  commandersActProductName: string;
};

const SEO: React.FC<SEOProps> = (props) => {
  const {
    description,
    title,
    socialTitle,
    socialDescription,
    socialImage,
    twitterImage,
    alternates,
    path,
    selligentMainTag,
    commandersActPageName,
    commandersActProductName,
  } = props;

  const siteMetadata: SiteMetaDataType = useSiteMetadata();
  const metaTitle = title;
  const metaDescription = description || siteMetadata?.description;
  const link = [];
  const meta = [];
  const addMeta = getMetaSetter(meta);

  addMeta('og:type', 'website');
  addMeta('og:url', `${siteMetadata.siteUrl}${path.url}/`);
  addMeta('og:site_name', siteMetadata.title);
  addMeta('twitter:card', 'summary');
  addMeta('twitter:site', `${siteMetadata.siteUrl}${path.url}`);

  if (metaDescription) {
    addMeta('description', metaDescription);
  }

  if (socialTitle) {
    addMeta('og:title', socialTitle);
    addMeta('twitter:title', socialTitle);
  }

  if (socialDescription) {
    addMeta('og:description', socialDescription);
    addMeta('twitter:description', socialDescription);
  }

  if (socialImage?.fixed) {
    addMeta('og:image', fixedProtocol(socialImage));
    addMeta('og:image:width', '1200');
    addMeta('og:image:height', '630');
  }

  if (twitterImage?.fixed) {
    addMeta('twitter:image', fixedProtocol(twitterImage));
  }

  if (alternates?.length) {
    alternates.forEach((alternate) => {
      link.push({
        rel: 'alternate',
        hrefLang: alternate.lang,
        href: `${siteMetadata.siteUrl}${alternate.url}`,
      });
    });
  }

  const extraHelmetProps: any = {};

  extraHelmetProps.script = [
    {
      type: 'text/javascript',
      innerHTML: getSelligentScript(isProductionEnv, selligentMainTag, path),
    },
  ];

  // CHAN-6188: CommandersAct
  extraHelmetProps.script.push({
    type: 'text/javascript',
    innerHTML: getCommanderActScript(
      commandersActPageName,
      commandersActProductName,
      path,
    ),
  });

  return (
    <Helmet
      {...extraHelmetProps}
      htmlAttributes={{
        lang: path.lang,
      }}
      title={metaTitle}
      titleTemplate={`%s | ${siteMetadata.title}`}
      link={link}
      meta={meta}
      encodeSpecialCharacters={false}
    />
  );
};

export default SEO;

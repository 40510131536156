import React, { ReactChild } from 'react';
import styled, { ThemeProvider } from 'styled-components';

import { isDarkTheme, getBackgroundColor } from '@/utils/StyleUtils';
import { mediaQuery } from '@/utils/helpers';
type Props = {
  id?: string;
  color?: string;
  padding?: string;
  children: ReactChild | ReactChild[];
  margin?: string;
  overflow?: string;
  className?: string;
  name?: string;
};
type WrapperProps = {
  color?: string;
  padding?: string;
  margin?: string;
  overflow?: string;
};

// merge 2 blocks with the same color and set a margin of 5.6rem
const MERGE_PADDING_NORMAL = '5.6rem';
const MERGE_PADDING_SMALL = '4.8rem';
const MERGE_PADDING_XSMALL = '3.2rem';
const Wrapper = styled.section<WrapperProps>`
  scroll-margin-top: 7.2rem;
  ${({ padding, color }) => {
    switch (padding) {
      case 'none':
        return 'padding:0;';
      case 'xsmall':
        return `padding:3.2rem 0;
        ${mediaQuery.md('padding:4.8rem 0;')}
        ${
          !color.includes('gradient') &&
          `& + section[data-color='${color}'] {
          margin-top: calc(-3.2rem - 4.8rem + ${MERGE_PADDING_XSMALL});
        }
        & + section[data-color='${color}'][data-padding='xsmall'] {
          margin-top: calc(-3.2rem * 2 + ${MERGE_PADDING_XSMALL});
        }`
        }
      ${mediaQuery.md(`
      ${
        !color.includes('gradient') &&
        `& + section[data-color='${color}'] {
        margin-top: calc(-4.8rem - 11.2rem + ${MERGE_PADDING_SMALL});
      }
      & + section[data-color='${color}'][data-padding='small'] {
        margin-top: calc(-4.8rem - 7.2rem + ${MERGE_PADDING_SMALL});
      }
      & + section[data-color='${color}'][data-padding='xsmall'] {
        margin-top: calc(-4.8rem * 2 + ${MERGE_PADDING_SMALL});
      }
      & + section[data-color='${color}'][data-padding='none'] {
        margin-top: calc(-4.8rem + ${MERGE_PADDING_SMALL});
      }
      & + section[data-color='${color}'][data-padding='bottomOnly'] {
        margin-top: calc(-4.8rem + ${MERGE_PADDING_SMALL});
      }`
      }
      `)}`;
      case 'small':
        return `padding:4.8rem 0;
        ${mediaQuery.md(`
      padding:4.8rem 0;
      `)}${
          !color.includes('gradient') &&
          `& + section[data-color='${color}'] {
          margin-top: calc(-4.8rem * 2 + ${MERGE_PADDING_SMALL});
        }
        & + section[data-color='${color}'][data-padding='xsmall'] {
          margin-top: calc(-3.2rem - 4.8rem + ${MERGE_PADDING_XSMALL});
        }`
        }
      ${mediaQuery.md(`
      ${
        !color.includes('gradient') &&
        `& + section[data-color='${color}'] {
        margin-top: calc(-7.2rem - 11.2rem + ${MERGE_PADDING_NORMAL});
      }
      & + section[data-color='${color}'][data-padding='small'] {
        margin-top: calc(-7.2rem * 2 + ${MERGE_PADDING_SMALL});
      }
      & + section[data-color='${color}'][data-padding='xsmall'] {
        margin-top: calc(-7.2rem - 4.8rem + ${MERGE_PADDING_SMALL});
      }
      & + section[data-color='${color}'][data-padding='none'] {
        margin-top: calc(-7.2rem + ${MERGE_PADDING_SMALL});
      }
      & + section[data-color='${color}'][data-padding='bottomOnly'] {
        margin-top: calc(-7.2rem + ${MERGE_PADDING_SMALL});
      }`
      }
      `)}`;

      case 'bottomOnly':
        return `padding:0 0 4.8rem;
        ${mediaQuery.md(`
        padding:0 0 11.2rem;
        `)}
        ${
          !color.includes('gradient') &&
          `& + section[data-color='${color}'] {
          margin-top: calc(-4.8rem * 2 + ${MERGE_PADDING_SMALL});
        }
        & + section[data-color='${color}'][data-padding='none'] {
          margin-top: calc(-4.8rem + ${MERGE_PADDING_SMALL});
        }
        & + section[data-color='${color}'][data-padding='bottomOnly'] {
          margin-top: calc(-4.8rem + ${MERGE_PADDING_SMALL});
        }
        & + section[data-color='${color}'][data-padding='xsmall'] {
          margin-top: calc(-4.8rem -3.2rem + ${MERGE_PADDING_SMALL});
        }
        `
        }
      ${mediaQuery.md(`
      ${
        !color.includes('gradient') &&
        `& + section[data-color='${color}'] {
        margin-top: calc(-11.2rem * 2 + ${MERGE_PADDING_NORMAL});
      }
      & + section[data-color='${color}'][data-padding='none'] {
        margin-top: calc(-11.2rem + ${MERGE_PADDING_NORMAL});
      }
      & + section[data-color='${color}'][data-padding='xsmall'] {
        margin-top: calc(-11.2rem - 4.8rem + ${MERGE_PADDING_NORMAL});
      }
      & + section[data-color='${color}'][data-padding='small'] {
        margin-top: calc(-11.2rem - 7.2rem + ${MERGE_PADDING_NORMAL});
      }
      `
      }
      `)}`;
      case 'normal':
      default:
        return `padding:4.8rem 0;
        ${mediaQuery.md(`
          padding:11.2rem 0;
        `)}
        ${
          !color.includes('gradient') &&
          `& + section[data-color='${color}'] {
          margin-top: calc(-4.8rem * 2 + ${MERGE_PADDING_SMALL});
        }
        & + section[data-color='${color}'][data-padding='none'] {
          margin-top: calc(-4.8rem + ${MERGE_PADDING_SMALL});
        }
        & + section[data-color='${color}'][data-padding='bottomOnly'] {
          margin-top: calc(-4.8rem + ${MERGE_PADDING_SMALL});
        }
        & + section[data-color='${color}'][data-padding='xsmall'] {
          margin-top: calc(-4.8rem -3.2rem + ${MERGE_PADDING_SMALL});
        }
        `
        }
      ${mediaQuery.md(`
      ${
        !color.includes('gradient') &&
        `& + section[data-color='${color}'] {
        margin-top: calc(-11.2rem * 2 + ${MERGE_PADDING_NORMAL});
      }
      & + section[data-color='${color}'][data-padding='none'] {
        margin-top: calc(-11.2rem + ${MERGE_PADDING_NORMAL});
      }
      & + section[data-color='${color}'][data-padding='xsmall'] {
        margin-top: calc(-11.2rem - 4.8rem + ${MERGE_PADDING_NORMAL});
      }
      & + section[data-color='${color}'][data-padding='small'] {
        margin-top: calc(-11.2rem - 7.2rem + ${MERGE_PADDING_NORMAL});
      }
      `
      }
      `)}`;
    }
  }}

  ${({ color }) =>
    color.includes('gradient')
      ? `background-image: ${color};`
      : `background-color: ${color};`}
      background: ${({ color }) => color} !important;
  margin: ${({ margin }) => margin || ''};
`;

const BlockWrapper: React.FC<Props> = ({
  id,
  color,
  padding,
  margin,
  children,
  className,
}) => {
  return (
    <Wrapper
      id={id}
      padding={padding}
      margin={margin}
      color={getBackgroundColor(color)}
      className={className}
      data-color={getBackgroundColor(color)}
      data-padding={padding}
    >
      <ThemeProvider theme={{ dark: isDarkTheme(color) }}>
        {children}
      </ThemeProvider>
    </Wrapper>
  );
};
export default BlockWrapper;

import { fixedProtocol } from '@/utils/URLUtils';

type MetaBase = { content: string };
type MetaNameItem = { name: string } & MetaBase;
type MetaPropertyItem = { property: string } & MetaBase;
type MetaItem = MetaNameItem | MetaPropertyItem;

const OG_PREFIX = 'og:';
const IMAGE_SUFFIX = ':image';

const getMetaProperties = (name: string, content: string): MetaItem => {
  const processedContent = name.endsWith(IMAGE_SUFFIX)
    ? fixedProtocol(content)
    : content;

  if (name.startsWith(OG_PREFIX)) {
    return {
      property: name,
      content: processedContent,
    };
  }

  return {
    name,
    content: processedContent,
  };
};

const getMetaSetter =
  (array: MetaItem[]) =>
  (name: string, content: string): void => {
    array.push(getMetaProperties(name, content));
  };

export default getMetaSetter;
